
const clsx = (...args) => args.filter(Boolean).join(" ");

const Flaticon = ({ name, type, size, className, style }) => {
  return (
    <i
      style={ { ...style, fontSize: size } }
      className={ clsx(className, "flex justify-center items-center fi", "fi" + (type ? "-" + type : "") + "-" + (name ?? 'question')) }
    />
  );
};

export default Flaticon;
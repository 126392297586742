import React, { createContext, useContext, useState } from "react";
import Flaticon from "../../components/Flaticon/Flaticon";
import { ContextureButton, ContextureCategory, ContexturePanel } from "../../components/Contexture/Contexture";
import { ContextureButtonStyle, ContexturePanelStyle } from "../../assets/styles";


const CONTEXT_MENU_OFFSET = 5;

const ContextMenu = () => {
  const { contextMenu, showContextMenu, setShowContextMenu, contextMenuPosition, contextMenuData } = useContextMenu();

  return (
    <ContexturePanel
      simple={ true }
      className={ ContexturePanelStyle }
      show={ showContextMenu }
      position={ { x: contextMenuPosition.x + CONTEXT_MENU_OFFSET, y: contextMenuPosition.y + CONTEXT_MENU_OFFSET } }
      onClose={ () => setShowContextMenu(false) }
    >
      { contextMenu?.map((context) => {
        if (context instanceof Array) {
          return context?.map(({ id, name, icon, onClick }, k) => {
            return (
              <ContextureButton
                className={ ContextureButtonStyle }
                key={ id }
                onClick={ (e) => {
                  if (onClick) {
                    setShowContextMenu(false);
                    onClick(contextMenuData, e);
                  }
                } }
              >
                <Flaticon name={ icon.name } type={ icon.type } size={ 16 } className="w-2" />
                <span>{ name }</span>
              </ContextureButton>
            );
          });
        } else if (context instanceof Object) {
          return (
            <ContextureCategory key={ context.id } label={ context.name }>
              { context.items.map(({ id, name, icon, onClick }, k) => (
                <ContextureButton
                  className={ ContextureButtonStyle }
                  key={ id }
                  onClick={ (e) => {
                    if (onClick) {
                      setShowContextMenu(false);
                      onClick(contextMenuData, e);
                    }
                  } }
                >
                  <Flaticon name={ icon.name } type={ icon.type } size={ 16 } className="w-2" />
                  <span>{ name }</span>
                </ContextureButton>
              )) }
            </ContextureCategory>
          );
        } else {
          return null;
        }
      }) }
    </ContexturePanel>
  );
};

const ContextMenuContext = createContext();

const ContextMenuProvider = ({ children }) => {
  const [ contextMenu, setContextMenu ] = useState(undefined);
  const [ showContextMenu, setShowContextMenu ] = useState(false);
  const [ contextMenuPosition, setContextMenuPosition ] = useState({ x: 0, y: 0 });
  const [ contextMenuData, setContextMenuData ] = useState(undefined);

  const value = {
    setContextMenu: ({ context, position, data }) => {
      setContextMenu(context);
      setContextMenuPosition(position);
      setContextMenuData(data);
      setShowContextMenu(true);
    },
    contextMenu,
    contextMenuData,
    showContextMenu,
    setShowContextMenu,
    contextMenuPosition,
    setContextMenuPosition
  };

  return (
    <ContextMenuContext.Provider value={ value }>
      { children }
    </ContextMenuContext.Provider>
  );
};

const useContextMenu = () => useContext(ContextMenuContext);

export default ContextMenu;
export { ContextMenuProvider, useContextMenu };
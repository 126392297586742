import { useEffect, useState } from "react";
import req from "../../../../modules/Request";
import { useNotify } from "../../../components/Notify";


const useDevices = () => {
  const [ loadingDevices, setLoadingDevices ] = useState(false);
  const [ devices, setDevices ] = useState([]);
  const { notify } = useNotify();
  
  const fetchDevices = async () => {
    setLoadingDevices(true);

    const res = await req('select-devices');

    if(res.success) {
      setDevices(res.data);
    } else {
      notify("Error", "Please contact the administrator", 'error');
    }

    setLoadingDevices(false);
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  return { devices, setDevices, fetchDevices, loadingDevices };
};

export default useDevices;
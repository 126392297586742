

const TaskCell = ({ task, splitTask }) => {
  return (
    task.name + (splitTask != undefined ? (
      +task.resources > 1 ? (
        ` [${+splitTask.duration * +task.resources}/${+task.resources}]`
      ) : (
        ` [${+splitTask.duration}]`
      )
    ) : (
      +task.resources > 1 ? (
        ` [${+task.duration * +task.resources}/${+task.resources}]`
      ) : (
        ` [${+task.duration}]`
      )
    ))
  );
};

export default TaskCell;
import { useEffect, useState } from "react";
import Flaticon from "../../../../components/Flaticon/Flaticon";
import sls from "react-secure-storage";
import { clsx } from "../../../../modules/Utilkit/Utilkit";
import req from "../../../../modules/Request";
import { Radio, RadioGroup } from "@headlessui/react";
import * as session from "../../../../modules/Stash/Stash";
import { ContextureButton, ContextureCategory, ContextureChild, ContexturePanel } from "../../../../components/Contexture/Contexture";
import { FormifyCombobox, FormifyComboboxOption, FormifySelect, FormifySelectOption } from "../../../../components/Formify/Formify";
import { FormifyComboboxStyle, ContextureButtonStyle, ContexturePanelStyle, FormifySelectStyle } from "../../../../assets/styles";

const POS_X = 175;
const POS_Y = 34;

const VIEWMODE_OPTIONS = [
  { label: 'Settimana', value: 'weeks' },
  { label: 'Giorno', value: 'days' }
];

const FilterPanel = ({ show, onClose, selectedCommessa, onSubmit, setViewType, viewType }) => {
  const [ suggs, setSuggs ] = useState(session.getStore("commesse") || []);
  const [ commessa, setCommessa ] = useState(selectedCommessa);
  const [ commessaType, setCommessaType ] = useState(selectedCommessa ? selectedCommessa[ 0 ] : 'M');
  const commessaTypeList = [
    { label: 'Tutto', value: 'tutto' },
    { label: 'Tipo M', value: 'M' },
    { label: 'Tipo C', value: 'C' }
  ];

  useEffect(() => {
    req('get-commesse').then((res) => {
      if (res.success) {
        const newSuggs = [
          { label: 'Tutto', value: 'Tutto' },
          ...res.data.commesse.map(commessa => ({ label: commessa, value: commessa }))
        ];
        session.addStore("commesse", newSuggs);
        setSuggs(newSuggs);
      }
    });
  }, []);

  const handleSubmitCommessa = (e) => {
    e.preventDefault();
    onSubmit(commessa);
    sls.setItem("tactical-planning-selected-commessa", commessa);
    onClose();
  };

  return (
    <ContexturePanel
      simple={ true }
      className={ ContexturePanelStyle }
      onClose={ onClose }
      show={ show }
      position={ { x: POS_X, y: POS_Y } }
    >
      { /** Visualizza per: settimana, giorno */ }
      <ContextureCategory label="Generali">
        <div className="flex justify-start items-center px-1 py-0.5 w-full space-x-2 text-nowrap">
          <ContextureChild>
            <ContextureButton className={ ContextureButtonStyle }>
              <Flaticon name="calendar" type="rr" className="w-4 h-4" />

              <span className="text-gray-800 text-start flex-1">
                Visualizzazione
              </span>

              <Flaticon name="angle-right" type="rr" size={ 11 } />
            </ContextureButton>

            <ContexturePanel className={ ContexturePanelStyle } position={ { x: POS_X + 190, y: POS_Y + 27 } }>
              <RadioGroup value={ viewType } onChange={ setViewType }>
                { VIEWMODE_OPTIONS.map((option) => (
                  <Radio key={ option.value } value={ option.value }>
                    { ({ checked }) => (
                      <ContextureButton className={ clsx(ContextureButtonStyle, checked && "bg-green-200 hover:bg-green-200") }>
                        <Flaticon name="check" type="rr" className={ clsx("h-4 w-4", !checked && "invisible") } />

                        <span className="flex space-x-2">
                          { option.label }
                        </span>
                      </ContextureButton>
                    ) }
                  </Radio>
                )) }
              </RadioGroup>
            </ContexturePanel>
          </ContextureChild>
        </div>
      </ContextureCategory>

      { /** Numero Commessa */ }
      <ContextureCategory label="Commessa">
        <div className="space-y-1">
          <FormifySelect
            className={ FormifySelectStyle }
            value={ commessaType }
            onChange={ (value) => setCommessaType(value) }
          >
            { commessaTypeList.map(({ label, value }) => (
              <FormifySelectOption
                className={ clsx(
                  "flex px-1 space-x-1 select-none cursor-pointer",
                  value === commessaType ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
                )}
                key={ value }
                value={ value }
                label={ label }
              >
                <div className="flex space-x-2 label  duration-75 text-nowrap">
                  <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-800", value === commessaType ? "visible" : "invisible") } />
                  <span>{ label }</span>
                </div>
              </FormifySelectOption>
            )) }
          </FormifySelect>

          <form className="flex" onSubmit={ handleSubmitCommessa }>
            <FormifyCombobox
              options={ suggs }
              value={ commessa }
              className={ FormifyComboboxStyle }
              width={ 175 }
              onChange={ (value) => setCommessa(value) }
            >
              { suggs.filter(({ value }) => {
                if(commessaType === 'tutto') {
                  return true;
                } else {
                  return value[0] === commessaType
                }
              }).map(({ label, value }, index) => (
                <FormifyComboboxOption
                className={ clsx(
                  "flex px-1 space-x-1 select-none cursor-pointer",
                  value === selectedCommessa ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
                )}
                  key={ index }
                  value={ value }
                  label={ label }
                >
                  <Flaticon name="check" type="rr" className={ clsx("text-green-800", value === selectedCommessa ? "visible" : "invisible") } />
                  <span>{ value }</span>
                </FormifyComboboxOption>
              )) }
            </FormifyCombobox>

            <button type="submit" className="bg-green-500 px-2 rounded-r-sm flex justify-center items-center">
              <Flaticon
                name="angle-right"
                type="rr"
                className="w-4 h-4 text-white"
              />
            </button>
          </form>
        </div>
      </ContextureCategory>
    </ContexturePanel>
  );
};

export default FilterPanel;
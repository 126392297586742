import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import React, { useEffect, useRef, useState } from "react";
const clsx = (...args) => args.filter(Boolean).join(" ");

const Contexture = ({ children }) => {
  const [ show, setShow ] = useState(false);


  return (
    <div className="relative">
      { React.Children.map(children, (child, index) => {
        if (child.type === ContextureSwitch) {
          return (
            <ContextureSwitch
              key={ index }
              { ...child.props }
              onClick={ () => setShow(!show) }
            >
              { child.props.children }
            </ContextureSwitch>
          );
        } else if (child.type === ContexturePanel) {
          return (
            <ContexturePanel
              key={ index }
              { ...child.props }
              show={ show }
              onClose={ () => setShow(false) }
            >
              { child.props.children }
            </ContexturePanel>
          );
        } else {
          return null;
        }
      }) }
    </div>
  );
};

const ContextureSwitch = ({ onClick, className, children }) => {
  return (
    <button
      onClick={ onClick }
      className={ className }
    >
      { children }
    </button>
  );
};

const ContexturePanel = ({ show, onClose, position, children, className, simple, enter, enterFrom, enterTo, leave, leaveFrom, leaveTo, outClose }) => {
  const firstEnter = useRef(false);

  const isFirstOpen = useRef(true);

  const handleMouseEnter = () => {
    if (!firstEnter.current) {
      firstEnter.current = true;
    }
  };

  useEffect(() => {
    if (!show) {
      isFirstOpen.current = true;
      return;
    }

    const handleClickOutside = (e) => {
      if (e.target.closest('.contexture') || e.target.classList.contains('contexture')) return;
      if (isFirstOpen.current) return;

      isFirstOpen.current = true;
      firstEnter.current = false;

      onClose();
    };

    window.addEventListener('mousedown', handleClickOutside);

    setTimeout(() => isFirstOpen.current = false, 50);
    return () => window.removeEventListener('mousedown', handleClickOutside);
  }, [ show ]);

  const handleMouseLeave = () => {
    if (firstEnter.current && outClose) {
      firstEnter.current = false;
      isFirstOpen.current = true;
      onClose();
    }
  };

  if (children instanceof Function) {
    children = children({ onClose });
  }

  if (simple) {
    if (position && position?.x && position?.y) {
      return (
        <Transition
          as="div"
          show={ show }
          onMouseEnter={ handleMouseEnter }
          onMouseLeave={ handleMouseLeave }
          className={ clsx(
            "fixed z-50 text-nowrap outline-none contexture",
            (position?.y > window.innerHeight * 0.75) && "-translate-y-full",
            (position?.x > (window.innerWidth * 0.75)) && "-translate-x-full",
          ) }
          style={ { left: position?.x, top: position?.y } }
        >
          { position?.y > window.innerHeight * 0.75 && position?.x > window.innerWidth * 0.75 ? (
            <TransitionChild
              as="div"
              className={ className }
              enter="transition transform duration-100 ease-out"
              enterFrom="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-100 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) : position?.y > window.innerHeight * 0.75 ? (
            <TransitionChild
              as="div"
              className={ className }
              enter="transition transform duration-100 ease-out"
              enterFrom="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-100 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) : position?.x > window.innerWidth * 0.75 ? (
            <TransitionChild
              as="div"
              className={ className }
              enter="transition transform duration-100 ease-out"
              enterFrom="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-100 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) : (
            <TransitionChild
              as="div"
              className={ className }
              enter="transition transform duration-100 ease-out"
              enterFrom="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
              enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
              leave="transition transform duration-100 ease-in"
              leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
              leaveTo="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
            >
              { children }
            </TransitionChild>
          ) }
        </Transition>
      );
    } else {
      return (
        <Transition
          as="div"
          show={ show }
          className={ clsx(className, "fixed z-50 outline-none contexture") }
          enter={ enter ?? "transition transform duration-100 ease-out" }
          enterFrom={ enterFrom ?? "opacity-0 scale-0" }
          enterTo={ enterTo ?? "opacity-100 scale-100" }
          leave={ leave ?? "transition transform duration-100 ease-in" }
          leaveFrom={ leaveFrom ?? "opacity-100 scale-100" }
          leaveTo={ leaveTo ?? "opacity-0 scale-0" }
          onMouseEnter={ handleMouseEnter }
          onMouseLeave={ handleMouseLeave }
        >
          { children }
        </Transition>
      );
    }
  } else {
    return (
      <Transition
        as={ Dialog }
        onClose={ onClose }
        show={ show }
        className={ clsx(
          "fixed z-50 text-nowrap outline-none",
          (position?.y > window.innerHeight * 0.75) && "-translate-y-full",
          (position?.x > (window.innerWidth * 0.75)) && "-translate-x-full",
        ) }
        style={ { left: position?.x, top: position?.y } }
      >
        { (position?.y > window.innerHeight * 0.75 && position?.x > window.innerWidth * 0.75) ? (
          <TransitionChild
            as="div"
            className={ className }
            enter="transition transform duration-100 ease-out"
            enterFrom="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-100 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 translate-x-1/2 translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) : position?.y > window.innerHeight * 0.75 ? (
          <TransitionChild
            as="div"
            className={ className }
            enter="transition transform duration-100 ease-out"
            enterFrom="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-100 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 -translate-x-1/2 translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) : position?.x > window.innerWidth * 0.75 ? (
          <TransitionChild
            as="div"
            className={ className }
            enter="transition transform duration-100 ease-out"
            enterFrom="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-100 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 translate-x-1/2 -translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) : (
          <TransitionChild
            as="div"
            className={ className }
            enter="transition transform duration-100 ease-out"
            enterFrom="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
            enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
            leave="transition transform duration-100 ease-in"
            leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
            leaveTo="opacity-0 scale-0 -translate-x-1/2 -translate-y-1/2"
          >
            { children }
          </TransitionChild>
        ) }
      </Transition>
    );
  }
};

const ContextureCategory = ({ children, label }) => {
  return (
    <div className="border-t mt-2 pt-2.5 w-full flex flex-col items-stretch justify-center relative">
      { label && (
        <div className="text-sm max-w-full absolute bg-white top-0 left-[1px] pl-1 pr-2 -translate-y-1/2 text-gray-600 overflow-x-hidden overflow-ellipsis">
          { label }
        </div>
      ) }

      { children }
    </div>
  );
};

const ContextureButton = ({ children, className, onClick, onMouseEnter, onMouseLeave }) => {
  return (
    <button
      type="button"
      className={ className ?? "" }
      onClick={ onClick }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }
    >
      { children }
    </button>
  );
};

const ContextureRow = ({ children }) => {
  return (
    <div className="flex justify-start items-center px-1 text-nowrap rounded-sm py-0.5 w-full">
      { children }
    </div>
  );
};

const ContextureChild = ({ children }) => {
  const timeout = useRef(null);
  const [ show, setShow ] = useState(false);

  return (
    <>
      { children.map((child, index) => {
        if (child.type === ContextureButton) {
          return (
            <ContextureButton
              key={ index }
              className={ child.props.className }
              onMouseEnter={ () => {
                if (timeout.current) {
                  clearTimeout(timeout.current);
                };

                timeout.current = setTimeout(() => {
                  setShow(true);
                }, 500);
              } }
              onClick={ () => {
                if (timeout.current) {
                  clearTimeout(timeout.current);
                };

                setShow(true);
              } }
              onMouseLeave={ () => {
                if (timeout.current) {
                  clearTimeout(timeout.current);
                }
              } }
            >
              { child.props.children }
            </ContextureButton>
          );
        } else if (child.type === ContexturePanel) {
          return (
            <ContexturePanel
              simple={ true }
              outClose={ true }
              className={ child.props.className }
              key={ index }
              show={ show }
              onClose={ () => setShow(false) }
              position={ child.props.position }
            >
              { child.props.children }
            </ContexturePanel>
          );
        } else {
          return null;
        }
      }) }
    </>
  );
};

export {
  Contexture,
  ContextureSwitch,
  ContexturePanel,
  ContextureCategory,
  ContextureButton,
  ContextureRow,
  ContextureChild
};
import { createContext, useContext, useState } from 'react';
import Loady from '../../components/Loady/Loady';

const LoadingContext = createContext();

const LoadingProvider = ({ children }) => {
  const [ showLoadingScreen, setShowLoadingScreen ] = useState(false);

  const value = {
    showLoadingScreen,
    setShowLoadingScreen
  };

  return (
    <LoadingContext.Provider value={ value }>
      { children }

      <Loady
        className="fixed left-0 top-0 w-full h-full"
        size="96px"
        show={ showLoadingScreen }
      />
    </LoadingContext.Provider>
  );

};

const useLoadingContext = () => useContext(LoadingContext);

export { LoadingProvider, useLoadingContext };
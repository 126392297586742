import { useEffect, useState } from "react";
import { useNotify } from "../../../components/Notify";
import req from "../../../../modules/Request";
import Moment from "../../../../modules/Moment/Moment";
import { clsx } from "../../../../modules/Utilkit/Utilkit";
import { FormifyInput } from "../../../../components/Formify/Formify";
import { FormifyInputStyle } from "../../../../assets/styles";


const TaskFormEdit = ({ task, setTasks, holidays, onClose, workingHours }) => {
  const { notify } = useNotify();

  const [ resources, setResources ] = useState(+task.resources);
  const [ start, setStart ] = useState(task.start);
  const [ duration, setDuration ] = useState(+task.duration);
  const [ end, setEnd ] = useState(task.end);
  const [ label, setLabel ] = useState(task.name == task.origin ? '' : task.name);

  const [ reqData, setReqData ] = useState(task);

  useEffect(() => {
    setResources(+task.resources);
    setStart(task.start);
    setDuration(+task.duration);
    setEnd(task.end);
    setLabel(task.name == task.origin ? '' : task.name);
  }, [ task ]);

  useEffect(() => {
    const data = { ...task };

    data.start = start;
    data.label = label;
    data.duration = duration;
    data.end = end;
    data.resources = resources;

    setReqData(data);
  }, [ resources, start, duration, end, label ]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (holidays.includes(start)) {
      notify('Data Inizio', "La data non può essere un giorno festivo.", 'error');
      return;
    } else if (holidays.includes(end)) {
      notify('Data Fine', "La data non può essere un giorno festivo.", 'error');
      return;
    }

    req('sync-task', { task: reqData }).then(async (res) => {
      if (res.success) {
        setTasks(tasks => tasks.map(t => t.id == task.id ? { ...reqData, moved: 1, name: reqData.label || task.origin } : t));
      } else {
        notify(res.error, "Errore durante l'aggiornamento del task.", 'error');
      }
    });

    onClose();
  };

  return (
    <form className="min-w-[220px] flex flex-col items-center text-center" onSubmit={ handleSubmit }>
      <div className="w-full font-semibold border-b border-gray-400">Modifica Task</div>

      <div className="flex flex-col justify-center items-center space-y-1 pb-2">
        <div className="flex flex-col w-full">
          <label>Titolo</label>

          <input
            className={ FormifyInputStyle }
            value={ label }
            maxLength={ 50 }
            onInput={ (e) => task.record === 'ST' && setLabel(e.target.value) }
            placeholder={ task.origin }
            disabled={ task.record != "ST" }
          />
        </div>

        <div className="flex flex-col w-full">
          <label>Data Inizio</label>

          <input
            className={ FormifyInputStyle }
            required={ true }
            type="date"
            value={ start }
            onInvalid={ (e) => {
              e.preventDefault();
              notify('Data Inizio', e.target.validationMessage, 'error');
            } }
            onInput={ (e) => {
              if (!holidays.includes(e.target.value)) {
                setStart(e.target.value);

                if (new Date(e.target.value) != 'Invalid Date' && new Date(e.target.value) > new Date('2000-01-01')) {
                  setEnd(Moment(e.target.value).end(duration / workingHours, holidays));
                }
              } else {
                notify('Data Inizio', "La data non può essere un giorno festivo.", 'error');
              }
            } }
          />
        </div>

        <div className="flex flex-col w-full">
          <label>Data Fine</label>

          <input
            className={ FormifyInputStyle }
            required={ true }
            type="date"
            min={ start }
            value={ end }
            onInvalid={ (e) => {
              e.preventDefault();
              notify('Data Fine', e.target.validationMessage, 'error');
            } }
            onInput={ (e) => {
              if (!holidays.includes(e.target.value)) {
                setEnd(e.target.value);
                setDuration((Moment(start).duration(e.target.value, holidays) + 1) * workingHours);
              } else {
                notify('Data Fine', "La data non può essere un giorno festivo.", 'error');
              }
            } }
          />
        </div>

        <div className="flex flex-col w-full">
          <label>Durata Per Persona (Ore)</label>

          <input
            className={ FormifyInputStyle }
            required={ true }
            type="number"
            min={ 0 }
            value={ +duration }
            onInput={ (e) => {
              setDuration(+e.target.value);
              setEnd(Moment(start).end(+e.target.value / workingHours, holidays));
            } }
          />
        </div>

        <div className="flex flex-col w-full">
          <label>Numero Persone</label>

          <input
            className={ FormifyInputStyle }
            required={ true }
            min={ 1 }
            step={ 1 }
            type="number"
            onInvalid={ (e) => {
              e.preventDefault();
              notify('Errore', e.target.validationMessage, 'error');
            } }
            value={ resources }
            onInput={ (e) => {
              if(e.target.value > 0) {
                const newDuration = duration * resources / +e.target.value;

                setDuration(newDuration);
                setEnd(Moment(start).end(newDuration / workingHours, holidays));
                setResources(+e.target.value)
              }
            } }
          />
        </div>

        <div className="flex flex-col w-full">
          <label>Durata Totale (Ore)</label>

          <input className={ FormifyInputStyle } disabled={ true } value={ resources * duration }/>
        </div>

        <button
          className={ clsx(
            "border disabled:bg-green-300 disabled:border-green-200 rounded-sm",
            "px-4 py-0.5 duration-75 bg-green-500 hover:bg-green-400",
            "border-green-400 hover:border-green-300 text-white"
          ) }
        >
          Salva
        </button>
      </div>
    </form>
  );
};

export default TaskFormEdit;
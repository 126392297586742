import { useLanguage } from "../../../components/Language";

const TimesheetPopup = ({ date, amountOfResources, amountOfHours, resources }) => {
  const { DICTIONARY: { TIMESHEETPOPUP: DICT } } = useLanguage();

  return (
    <div className="flex flex-col">
      <h1 className="font-semibold w-full border-b border-gray-400 flex justify-center">
        { DICT.TITLE }
      </h1>

      <div>
        <h2 className="w-full flex justify-center items-center">
          { date }
        </h2>

        <ul className="divide-y divide-gray-300 border-b-2 border-gray-300 px-1">
          <li className="flex justify-between px-1">
            <p className="text-base">{ DICT.WORKHOURS_ROW_NAME }</p>
            <p className="text-base">{ amountOfHours }</p>
          </li>

          <li className="flex items-center px-1 justify-between">
            <p className="text-base">{ DICT.RESOURCES_ROW_NAME }</p>
            <p className="text-base">{ amountOfResources }</p>
          </li>
        </ul>

        <ul className="border-b border-gray-300 divide-y divide-gray-300 px-1 w-full">
          <li className="flex items-center px-1 justify-between">
            <p className="font-semibold">{ DICT.PERSON_COL_NAME } </p>
            <p className="font-semibold">{ DICT.HOURS_COL_NAME }</p>
          </li>

          { resources?.map((detail) => {
            const resHours = detail.hours;
            const resName = detail.resource.split(' ').map((name) => name.slice(0, 1) + name.toLowerCase().slice(1)).join(' ');

            return (
              <li key={ date + resName } className="flex items-center px-1 justify-between">
                <p className="flex-1">{ resName }</p>
                <p>{ resHours }</p>
              </li>
            );
          }) }
        </ul>
      </div>
    </div>
  );
};

export default TimesheetPopup;
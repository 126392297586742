import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import './index.css';
import App from './app';
import { LanguageProvider } from './app/components/Language';
import { AuthProvider } from './app/components/Auth';
import { NotifyProvider } from './app/components/Notify';
import { ContextMenuProvider } from './app/components/ContextMenu';
import { NavigatorProvider } from './app/components/Navigator';
import { LoadingProvider } from './app/components/LoadingScreen';
import { TitleBarTrackerProvider } from './app/components/TitleBarTracker';
import { FloatPanelProvider } from './app/components/FloatPanel';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <LoadingProvider>
    <LanguageProvider>
      <NotifyProvider>
        <TitleBarTrackerProvider>
          <AuthProvider>
            <NavigatorProvider>
              <ContextMenuProvider>
                <FloatPanelProvider>
                  <App />
                </FloatPanelProvider>
              </ContextMenuProvider>
            </NavigatorProvider>
          </AuthProvider>
        </TitleBarTrackerProvider>
      </NotifyProvider>
    </LanguageProvider>
  </LoadingProvider>
);

serviceWorkerRegistration.unregister();

reportWebVitals();

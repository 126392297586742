import React from "react";
import LoadingAnimation from "./loading.svg";

const Loady = ({ show, children, color, size, className, label }) => {
  return show ? (
    <div className={ "flex flex-col justify-center items-center " + className }>
      { label && <div className="text-2xl font-bold my-4" style={ { color } }>{ label }</div> }
      <img src={ LoadingAnimation } alt="Loading..." style={ { width: size, height: size, color } } />
    </div>
  ) : (
    children
  );
};

export default Loady;

import { createContext, useContext, useEffect, useState } from "react";
import sls from "react-secure-storage";
import req from "../../modules/Request";
import SingInBackground from "../../assets/images/signin.jpeg";
import Logo from "../../assets/images/logo.png";
import { clsx } from "../../modules/Utilkit/Utilkit";
import { useNotify } from "./Notify";

function Auth() {
  const [ credentials, setCredentials ] = useState({ username: '', password: '' });
  const [ loading, setLoading ] = useState(false);
  const [ invalid, setInvalid ] = useState({ username: false, password: false });
  const { handleSignIn } = useAuth();
  const { notify } = useNotify();

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!credentials.username) {
      setInvalid(curr => ({ ...curr, username: true }));
      notify('Errore', 'Compila il campo "Username"', 'error');
      return;
    } else if (!credentials.password) {
      setInvalid(curr => ({ ...curr, password: true }));
      notify('Errore', 'Compila il campo "Password"', 'error');
      return;
    }

    setLoading(true);
    await handleSignIn(credentials);
    setLoading(false);
  };

  return (<>
    <div
      className="w-full h-full bg-white flex flex-col justify-center items-center px-2 relative"
      style={ {
        backgroundImage: `url(${SingInBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      } }
    >
      <div className="absolute w-full h-full bg-gradient-to-b from-white to-transparent to-40%" />

      <form
        className="flex justify-center max-w-[300px] w-full flex-col space-y-5 pb-6 py-4 px-3 z-10 rounded-md shadow-sm border bg-slate-50 border-slate-200"
        onSubmit={ onSubmit }
      >
        <div className="flex flex-col space-y-2 justify-center items-center">
          <img src={ Logo } className="w-20 select-none" draggable={ false } />
          <h1 className="mb-4 text-2xl">Accedi al PDP</h1>
        </div>
        <div className="flex flex-col w-full">
          <input
            autoComplete="username"
            placeholder="Username"
            className={ clsx(
              "mx-2 px-2 py-2 rounded-t-md shadow-sm text-sm border bg-white border-slate-300 text-center focus:ring-blue-500",
              invalid.username && 'ring-1 ring-red-500'
            ) }
            value={ credentials.username }
            onInvalid={ (e) => e.preventDefault() }
            onInput={ (e) => {
              setCredentials((curr) => ({ ...curr, username: e.target.value }));
              setInvalid(curr => ({ ...curr, username: false }));
            } }
          />
          <input
            autoComplete="current-password"
            type="password"
            placeholder="Password"
            className={
              clsx(
                "mx-2 px-2 py-2 rounded-b-md shadow-sm text-sm border bg-white border-slate-300 text-center focus:ring-blue-500",
                invalid.password && 'ring-1 ring-red-500'
              )
            }
            value={ credentials.password }
            onInvalid={ (e) => e.preventDefault() }
            maxLength={ 50 }
            onInput={ (e) => {
              setCredentials((curr) => ({ ...curr, password: e.target.value }));
              setInvalid(curr => ({ ...curr, password: false }));
            } }
          />
        </div>
        <button
          disabled={ loading }
          className={ clsx(
            "border p-1 mx-2 rounded-md shadow-sm text-sm font-semibold",
            "text-white disabled:bg-green-300 disabled:border-green-200",
            "bg-green-600 hover:bg-green-500 border-green-500 hover:border-green-400"
          ) }
        >
          { loading ? "Singing in..." : "Sign In" }
        </button>
      </form>

      <div className="text-xs absolute bottom-0 right-0 m-1 py-0.5 px-1 bg-white/60 rounded-md text-gray-600">
        Created by <a href="https://www.system-service.it/" className="text-blue-600 hover:text-blue-400" target="_blank">System Service Srl</a>
      </div>
    </div>
  </>);
}
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [ userData, setUserData ] = useState({});
  const [ authStatus, setAuthStatus ] = useState('loading');
  const [ authError, setAuthError ] = useState(null);
  const { notify } = useNotify();

  const setFatalError = (error) => {
    setAuthError(error);
    setAuthStatus('error');
  };

  const handleSignIn = async ({ username, password }) => {
    const res = await req('sign-in', { username, password, agent: navigator.userAgentData });

    if (res.success) {
      sls.setItem('token', res.data.token);

      const userData = res.data.userData;
      const username = userData.username;
      const fullname = userData.fullname;
      const initials = userData.fullname.split(' ').slice(0, 2).map((n) => n[ 0 ]).join('');

      setUserData({ username, fullname, initials });

      notify('Successo', 'Login effettuato con successo!', 'success');
      setAuthStatus('success');
    } else {
      setAuthStatus('sign-in-required');
      if (res.error === 'invalid-credentials') {
        notify('Errore', 'Username o Password non sono validi!', 'error');
      } else if (res.error === 'password-expired') {
        notify('Errore', 'La tua password è scaduta!', 'error');
      } else if (res.error === 'user-blocked') {
        notify('Errore', 'Il tuo account è stato bloccato!', 'error');
      } else if (res.error === 'access-danied') {
        notify('Errore', 'Accesso negato!', 'error');
      } else {
        notify('Errore', 'Errore sconosciuto!', 'error');
      }
    }
  };

  const handleSignOut = async () => {
    await req('sign-out').then(res => {
      if (res.success) {
        sls.removeItem('token');
        setAuthStatus('sign-in-required');
      } else {
        setFatalError(res.error);
      }
    }).catch(err => {
      setFatalError(err);
    });
  };

  useEffect(() => {
    const onTokenAuth = async () => {
      await req('sign-in-token').then(res => {
        if (res.success) {

          const userData = res.data.userData;
          const username = userData.username;
          const fullname = userData.fullname;
          const initials = userData.fullname.split(' ').map((n) => n[ 0 ]).slice(0, 2).join('');

          setUserData({ username, fullname, initials });

          setAuthStatus('success');
        } else {
          setAuthStatus('sign-in-required');
          if (res.error === 'invalid-credentials') {
            notify('Errore', 'Username o Password non sono validi!', 'error');
            sls.removeItem('token');
          } else if (res.error === 'password-expired') {
            notify('Errore', 'La tua password è scaduta!', 'error');
            sls.removeItem('token');
          } else if (res.error === 'user-blocked') {
            notify('Errore', 'Il tuo account è stato bloccato!', 'error');
            sls.removeItem('token');
          } else if (res.error === 'access-danied') {
            notify('Errore', 'Accesso negato!', 'error');
            sls.removeItem('token');
          } else {
            notify('Errore sconosciuto!', 'Prova a ricaricare la pagina o effettuare di nuovo login', 'error');
          }
        }
      });
    };

    if (sls.getItem('token')) {
      onTokenAuth();
    } else {
      setAuthStatus('sign-in-required');
    }
  }, []);

  return (
    <AuthContext.Provider value={ { authStatus, authError, handleSignIn, handleSignOut, setFatalError, userData } }>
      { children }
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export default Auth;
export { AuthProvider, useAuth };
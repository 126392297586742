import { useEffect, useState } from "react";
import req from "../../../../modules/Request";
import * as session from "../../../../modules/Stash/Stash";


function useHolidays() {
  const [ data, setData ] = useState(session.getStore('holidays') || []);

  const sync = async () => {
    const res = await req("get-holidays");

    if (res.success) {
      session.addStore('holidays', res.data);
      setData(res.data);
    }
  };

  useEffect(() => {
    sync();
  }, []);

  return data;
}

export default useHolidays;
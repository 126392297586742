import { createContext, useContext, useState } from "react";
import Modality from "../../components/Modality/Modality";
import Flaticon from "../../components/Flaticon/Flaticon";
import { Transition } from "@headlessui/react";
import config from "../../config/config.json";
import { clsx } from "../../modules/Utilkit/Utilkit";
import * as session from "../../modules/Stash/Stash";
import { useAuth } from "./Auth";
import { useTitleBarTracker } from "./TitleBarTracker";


import Logo from "../../assets/images/logo.png";
import { Contexture, ContextureButton, ContexturePanel, ContextureSwitch } from "../../components/Contexture/Contexture";
import { ContextureButtonStyle } from "../../assets/styles";

/* NAVBAR */
const Navbar = ({ nav, usernav }) => {
  const { isOverlayVisible } = useTitleBarTracker();
  const { handleSignOut } = useAuth();

  const [ showSignOutModal, setShowSignOutModal ] = useState(false);

  return (
    <div className={ clsx(
      "flex items-center select-none min-h-full h-fit w-[env(titlebar-area-width,100%)] duration-150 pl-1",
      isOverlayVisible && "pr-4"
    ) } >
      <SignOutModal
        show={ showSignOutModal }
        onClose={ () => setShowSignOutModal(false) }
        onSignOut={ () => {
          handleSignOut();
          setShowSignOutModal(false);
        } }
      />

      <NavbarLogo />

      <QuickAccessPanel />

      <div className="flex space-x-2 px-2 items-center">
        <AppsMenu nav={ nav } />

        <UserMenu usernav={ usernav } onSignOut={ () => setShowSignOutModal(true) } />
      </div>

    </div>
  );
};

/* SIGN OUT MODAL */
const SignOutModal = ({ show, onClose, onSignOut }) => {
  return (
    <Modality
      show={ show }
      onClose={ onClose }
      label="Conferma uscita"
      icon={ { name: "leave", type: "sr", className: "text-red-500" } }
      buttons={ [
        { name: "Annulla", onClick: onClose },
        { name: "Esci", onClick: onSignOut, styleSet: 'error' }
      ] }
    >
      Sei sicuro di voler uscire?
    </Modality>
  );
};

/* NAVBAR LOGO */
const NavbarLogo = () => {
  return (
    <div className={ clsx(
      "overflow-hidden flex pr-1",
      "h-[calc(env(titlebar-area-height,33px)*0.7)] [-webkit-app-region:drag]"
    ) }>
      <img className="select-none w-6" draggable={ false } src={ Logo } />
      <span className="font-semibold px-1">PDP</span>
    </div>
  );
};

/* QUICK ACCESS PANEL */
const QuickAccessPanel = () => {
  const { showQuickAccessPanel, quickAccessPanel } = useNavigator();
  const { isOverlayVisible } = useTitleBarTracker();

  return (
    <div className="flex-1 flex items-center">
      <Transition
        show={ showQuickAccessPanel }
        as="div"
        className="flex space-x-2 overflow-hidden"
        enter="transition ease-out duration-300"
        enterFrom="opacity-0 translate-x-10"
        enterTo="opacity-100 translate-x-0"
        leave="transition ease-in duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        { quickAccessPanel.map(({ name, onClick, icon, type, onInput }) => (
          type === 'button' ? (
            <button
              key={ name }
              className="flex items-center justify-center px-1.5 space-x-1 hover:bg-gray-200 rounded-md text-gray-800 text-nowrap"
              onClick={ onClick }
            >
              <Flaticon name={ icon.name } type={ icon.type } size={ "calc(env(titlebar-area-height,33px)*0.4)" } />
              <span className="ml-2 text-[calc(env(titlebar-area-height,33px)*0.45)]">{ name }</span>
            </button>
          ) : type === 'search' && (
            <div className="relative flex" key={ name }>
              <Flaticon
                className="absolute top-1/2 -translate-y-1/2 right-1.5 text-gray-400"
                name={ icon.name }
                type={ icon.type }
                size={ 16 }
              />
              <input
                type="text"
                className="pl-2 pr-7 p-0 rounded-md border border-transparent focus:border-blue-300 focus:outline-none focus:ring-0"
                placeholder={ name }
                onInput={ onInput }
              />
            </div>
          )
        )) }
      </Transition>

      { isOverlayVisible && (
        <div className="flex-1 w-full [-webkit-app-region:drag] h-[env(titlebar-area-height,33px)] top-0 left-0 z-50" />
      ) }
    </div>
  );
};

/* APPS MENU */
const AppsMenu = ({ nav }) => {
  const { active, go, setShowQuickAccessPanel } = useNavigator();

  return (
    <Contexture>
      <ContextureSwitch className="flex justify-center items-center rounded-full hover:bg-gray-200 duration-100 z-50 w-6 h-6">
        <Flaticon name="apps" type="sr" className="text-gray-600 " size={ 18 } />
      </ContextureSwitch>

      <ContexturePanel
        simple={ true }
        className={ clsx(
          "absolute flex flex-col space-y-1 p-1 rounded-sm top-8 -right-7 overflow-hidden",
          "bg-white shadow-lg focus:outline-none border border-gray-300"
        ) }
        enter="transition ease-out duration-150 origin-top-right"
        enterFrom="scale-0 -translate-y-6 -translate-x-12 opacity-0"
        enterTo="scale-100 translate-y-0 translate-x-0 opacity-100"
        leave="transition ease-in duration-150 origin-top-right"
        leaveFrom="scale-100 translate-y-0 translate-x-0 opacity-100"
        leaveTo="scale-0 -translate-y-6 -translate-x-12 opacity-0"
      >
        { ({ onClose }) => {
          return nav.map(({ id, name, description, icon, image, className }) => (
            <ContextureButton
              key={ id }
              as="button"
              className={ clsx(
                "flex items-center space-x-1 px-1 rounded-sm whitespace-nowrap select-none hover:bg-gray-200",
                active === id && "bg-gray-100"
              ) }
              onClick={ () => {
                if (id === active) return;
                setShowQuickAccessPanel(false);
                go(id);
                onClose();
              } }
            >
              <div className="w-10 h-10 flex justify-center items-center rounded-sm">
                { image ? (
                  <img src={ image } className="w-[32px]" />
                ) : (
                  <Flaticon name={ icon.name } type={ icon.type } size={ 20 } />
                ) }
              </div>

              <div className="flex flex-col items-start justify-center">
                <span className="font-semibold">{ name }</span>
                <span>{ description }</span>
              </div>
            </ContextureButton>

          ));
        } }
      </ContexturePanel>
    </Contexture>
  );
};

/* USER MENU */
const UserMenu = ({ usernav, onSignOut }) => {
  const { userData: { fullname, initials, username } } = useAuth();
  const softwareVersion = config.softwareVersion;

  return (
    <Contexture>
      <ContextureSwitch className="rounded-full bg-gray-300 border border-red-500 duration-100 flex justify-center items-center w-6 h-6">
        <div className="rounded-full flex justify-center items-center text-center font-medium text-sm w-full h-full text-[14px]">
          { initials }
        </div>
      </ContextureSwitch>

      <ContexturePanel
        simple={ true }
        className={ clsx(
          "absolute w-[180px] flex flex-col p-1 focus:outline-none border border-gray-300",
          "rounded-sm bg-white shadow-lg top-8 -right-2 overflow-hidden"
        ) }
        enter="transition ease-out duration-150 origin-top-right"
        enterFrom="scale-0 -translate-y-6 -translate-x-4 opacity-0"
        enterTo="scale-100 translate-y-0 translate-x-0 opacity-100"
        leave="transition ease-in duration-200 origin-top-right"
        leaveFrom="scale-100 translate-y-0 translate-x-0 opacity-100"
        leaveTo="scale-0 -translate-y-6 -translate-x-4 opacity-0"
      >
        { ({ onClose }) => {
          return (
            <>
              <div className="absolute top-0 left-0 w-full h-7 bg-red-200" />

              <div className="flex z-10 flex-col justify-center items-center">
                <div className="w-full flex flex-col justify-center items-center mb-1">
                  <div className="w-12 h-12 mb-2 flex justify-center items-center shadow-md bg-color rounded-full text-xl font-semibold bg-gray-300 text-gray-800 border border-red-500">
                    { initials }
                  </div>
                </div>

                <div className="bg-gray-200 px-1 rounded-md">
                  { username }
                </div>

                <div>
                  { fullname }
                </div>

                <div className="text-xs font-thin text-gray-400">
                  Software Version { softwareVersion }
                </div>

                <div className="flex flex-col w-full border-t pt-1 mt-2">
                  { usernav.map(({ id, name, className, onClick }) => (
                    <button
                      key={ id }
                      className={ clsx(
                        "flex w-full justify-center rounded-sm items-center",
                        "p-1.5 duration-150 hover:bg-gray-100 space-x-2 px-2"
                      ) }
                      onClick={ () => {
                        if (id === 'logout') {
                          onSignOut();
                        } else {
                          onClick();
                        }
                        onClose();
                      } }
                    >
                      <div className={ className }>{ name }</div>
                    </button>
                  )) }
                </div>
              </div>
            </>
          );
        } }
      </ContexturePanel>
    </Contexture>
  );
};

const NavigatorContext = createContext();

const NavigatorProvider = ({ children }) => {
  const [ active, setActive ] = useState(session.getActive() || 'dashboard');
  const [ params, setParams ] = useState(session.getParams() || {});
  const [ showQuickAccessPanel, setShowQuickAccessPanel ] = useState(false);
  const [ quickAccessPanel, setQuickAccessPanel ] = useState([]);

  const go = (_active, _params = {}) => {
    if (_active !== active) {
      session.setSession({ active: _active, params: _params });
      setActive(_active);
      setParams(_params);
    }
  };

  const value = {
    active,
    params,
    go,
    showQuickAccessPanel,
    setShowQuickAccessPanel,
    quickAccessPanel,
    setQuickAccessPanel: (items) => {
      if (showQuickAccessPanel) {
        setShowQuickAccessPanel(false);
      }

      setTimeout(() => {
        setQuickAccessPanel(items);
        setShowQuickAccessPanel(true);
      }, 350);
    }
  };

  return (
    <NavigatorContext.Provider value={ value }>
      { children }
    </NavigatorContext.Provider>
  );
};

const useNavigator = () => useContext(NavigatorContext);

export default Navbar;
export { useNavigator, NavigatorProvider };
import Flaticon from "../../../components/Flaticon/Flaticon";
import useBollature from "./hooks/useBollature";

export default function Dashboard() {
  const { bollature, loadingBollature, syncBollature, lastUpdateBollature } = useBollature();

  return (
    <div className="flex flex-col justify-center">


      <div className="p-4 w-full flex flex-col justify-center">
        <div className="flex flex-col w-full">
          <div className="text-2xl text-black-800 py-2 px-3 border-b border-gray-300 w-full flex justify-between text-start">
            <span className="font-semibold">Attività in corso</span>

            <div className="flex space-x-2 items-center">
              <span className="text-xs">
                { lastUpdateBollature ? (
                  "Ultimo Aggiornamento " + lastUpdateBollature
                ) : (
                  "Aggiornamento..."
                ) }
              </span>

              <button
                className={ loadingBollature ? "animate-spin" : undefined }
                disabled={ loadingBollature }
                onClick={ syncBollature }
              >
                <Flaticon
                  name="refresh"
                  type="br"
                  className="text-base"
                />
              </button>
            </div>
          </div>

          <div className="p-2 h-full w-full">
            <div className="bg-white shadow-md rounded-sm overflow-y-auto max-h-[820px] w-full">
              <table className="*:*:*:px-2 w-full text-sm">
                <thead>
                  <tr className="*:text-start *:py-2 *:bg-white z-10 shadow-md sticky top-0">
                    <th className="max-lg:hidden">Dipendente</th>
                    <th>Cognome Nome</th>
                    <th className="max-sm:hidden">Commessa</th>
                    <th className="max-md:hidden">Ordine di Produzione</th>
                    <th>Articolo</th>
                    <th>Descrizione</th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-gray-200">
                  { bollature?.length > 0 ? (
                    bollature?.map((item, i) => (
                      <tr key={ i }>
                        <td className="max-lg:hidden">{ item.DIPENDENTE }</td>
                        <td>{ item.COGNOMENOME }</td>
                        <td className="max-sm:hidden">{ item.COMMESSA }</td>
                        <td className="max-md:hidden">{ item.ORDINEPRODUZIONE }</td>
                        <td>{ item.ARTICOLODAPRODURRE }</td>
                        <td>{ item.DESCRIZIONEARTICOLO }</td>
                      </tr>
                    ))
                  ) : loadingBollature ? (
                    <tr>
                      <td colSpan="6" className="text-base py-2 text-center">Caricamento Dati...</td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-base py-2 text-center">Nessuna attività in corso.</td>
                    </tr>
                  ) }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
import { Radio, RadioGroup } from "@headlessui/react";
import Flaticon from "../../../../components/Flaticon/Flaticon";
import { clsx } from "../../../../modules/Utilkit/Utilkit";
import { ContextureButton, ContextureCategory, ContextureChild, ContexturePanel } from "../../../../components/Contexture/Contexture";
import { useRef, useState } from "react";
import { ContextureButtonStyle, ContexturePanelStyle } from "../../../../assets/styles";

const POS_X = 245;
const POS_Y = 34;

const MILTIPLER_OPTIONS = [
  { value: 0.7, label: 'Molto Piccolo' },
  { value: 0.8, label: 'Piccolo' },
  { value: 0.9, label: 'Medio' },
  { value: 1, label: 'Normale' }
];

const SettingsPanel = ({ show, onClose, fontSize, cellHeight, cellWidth, setParameter, parameters }) => {
  return (
    <ContexturePanel
      simple={ true }
      className={ ContexturePanelStyle }
      onClose={ onClose }
      show={ show }
      position={ { x: POS_X, y: POS_Y } }
    >
      <ContextureCategory label="Dimensioni">
        {/* Moltiplicatore */ }
        <ContextureChild>
          <ContextureButton className={ ContextureButtonStyle }>
            <Flaticon className="w-4 h-4" type="rr" name="text-size" />

            <span className="text-gray-800 text-start flex-1">
              Moltiplicatore
            </span>

            <Flaticon name="angle-right" type="rr" size={ 11 } />
          </ContextureButton>

          <ContexturePanel className={ ContexturePanelStyle } position={ { x: POS_X + 215, y: POS_Y + 25 } }>
            <ZoomSelectSetting
              fontSize={ fontSize }
              cellWidth={ cellWidth }
              cellHeight={ cellHeight }
              zoomOptions={ MILTIPLER_OPTIONS }
              selected={ +parameters?.sizeMultipler || 0.9 }
              onSelect={ (val) => setParameter('sizeMultipler', val) }
            />
          </ContexturePanel>
        </ContextureChild>

        {/* Altezza righe */ }
        <HighRowSetting icon={ { name: "text-height", type: "rr" } } label="Altezza righe" defaultValue={ parameters?.cellHeight || cellHeight } onChange={ (val) => setParameter('cellHeight', val) } />
      </ContextureCategory>

      <ContextureCategory label="Colori">
        <ColorSetting label="Oggi" defaultValue={ parameters?.todayColor || "#ff0000" } onChange={ (val) => setParameter('todayColor', val) } />

        <ColorSetting label="Giorno festivo" defaultValue={ parameters?.holidayColor || "#f0f0f0" } onChange={ (val) => setParameter('holidayColor', val) } />

        <ColorSetting label="Commessa" defaultValue={ parameters?.commessaColor || "#22c55e" } onChange={ (val) => setParameter('commessaColor', val) } />

        <ColorSetting label="Fase" defaultValue={ parameters?.faseTaskColor || "#22c55e" } onChange={ (val) => setParameter('faseTaskColor', val) } />

        <ColorSetting label="Sotto-Fase" defaultValue={ parameters?.grpModificabileTaskColor || "#22c55e" } onChange={ (val) => setParameter('grpModificabileTaskColor', val) } />

        <ColorSetting label="Attività [=1]" defaultValue={ parameters?.timesheetLowColor || "#d1d5db" } onChange={ (val) => setParameter('timesheetLowColor', val) } />

        <ColorSetting label="Attività [=2]" defaultValue={ parameters?.timesheetMediumColor || "#9ca3af" } onChange={ (val) => setParameter('timesheetMediumColor', val) } />

        <ColorSetting label="Attività [≥3]" defaultValue={ parameters?.timesheetHighColor || "#6b7280" } onChange={ (val) => setParameter('timesheetHighColor', val) } />
      </ContextureCategory>
    </ContexturePanel>
  );
};

const ZoomSelectSetting = ({ onSelect, selected, zoomOptions, cellWidth, cellHeight, fontSize }) => {
  return (
    <RadioGroup
      value={ selected }
      onChange={ (val) => onSelect(val) }
    >
      { zoomOptions.map((option) => (
        <Radio key={ option.value } value={ option.value }>
          { ({ checked }) => (
            <ContextureButton className={ clsx(ContextureButtonStyle, checked && "bg-green-200 hover:bg-green-200") }>
              <Flaticon name="check" type="rr" className={ clsx("h-4 w-4", !checked && "invisible") } />

              <div className="flex space-x-2">
                <h1 className="w-24 text-start">
                  { option.label }
                </h1>

                <div className="w-5">
                  <div
                    className="bg-white/60 border border-gray-300"
                    style={ { width: cellWidth * option.value, height: cellHeight * option.value } }
                  />
                </div>

                <div style={ { fontSize: fontSize * option.value } }>
                  { new Date().toISOString().split('T')[ 0 ].split('-').reverse().join('/') }
                </div>
              </div>
            </ContextureButton>
          ) }
        </Radio>
      )) }
    </RadioGroup>
  );
};

const HighRowSetting = ({ onChange, defaultValue, label, icon }) => {
  const timeoutRef = useRef(undefined);
  const [ value, setValue ] = useState(defaultValue);

  return (
    <div className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap">
      <Flaticon name={ icon.name } type={ icon.type } className="w-4 h-4" />

      <span className="text-gray-800 text-start flex-1 select-none">
        { label }
      </span>

      <div>
        <input
          className="pr-0 pl-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b w-16"
          type="number"
          value={ value }
          onInput={ (e) => {
            setValue(e.target.value);

            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
              onChange && onChange(e.target.value);
            }, 300);
          } }
        />
      </div>
    </div>
  );
};

const ColorSetting = ({ onChange, defaultValue, label }) => {
  const timeoutRef = useRef(undefined);
  const [ value, setValue ] = useState(defaultValue);

  return (
    <div className="flex justify-start items-center px-3 py-0.5 w-full space-x-2 text-nowrap">
      <span className="text-gray-800 text-start flex-1 select-none">
        { label }
      </span>

      <input
        className="p-0 m-0 w-12 h-6"
        type="color"
        value={ value }
        onInput={ (e) => {
          setValue(e.target.value);

          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }

          timeoutRef.current = setTimeout(() => {
            onChange && onChange(e.target.value);
          }, 300);
        } }
      />
    </div>
  );
};

export default SettingsPanel;
import { useEffect, useState } from "react";
import req from "../../../../modules/Request";


const useCalendar = () => {
  const [ calendar, setCalendar ] = useState([]);

  const insertDate = (date) => {
    const newCalendar = [...calendar];
    newCalendar.push(date.split('-').reverse().join('/'));
    setCalendar(newCalendar.sort((a, b) => {
      const aDate = new Date(a.split('/').reverse().join('-'));
      const bDate = new Date(b.split('/').reverse().join('-'));
      return bDate - aDate;
    }));

    req('insert-date', { date }).then(res => {
      if(!res.success) {
        console.error(res.error);
      }
    });
  }

  const deleteDate = (date) => {
    const newCalendar = calendar.filter(d => d !== date);
    setCalendar(newCalendar);

    req('delete-date', { date: date.split('/').reverse().join('-') }).then(res => {
      if(!res.success) {
        console.error(res.error);
      }
    });
  }

  useEffect(() => {
    const fetchCalendar = async () => {
      const res = await req('get-calendar');

      if(res.success) {
        setCalendar(res.data.sort((a, b) => {
          const aDate = new Date(a.split('/').reverse().join('-'));
          const bDate = new Date(b.split('/').reverse().join('-'));
          return bDate - aDate;
        }));
      } else {
        console.error('Error fetching calendar data');
      }
    }
    
    fetchCalendar();
  }, []);

  return { calendar, insertDate, deleteDate };
}

export default useCalendar;
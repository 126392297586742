import { useCallback, useEffect, useState } from "react";
import { useNotify } from "../../../components/Notify";
import req from "../../../../modules/Request";
import * as session from "../../../../modules/Stash/Stash";

function useTasks() {
  const { notify } = useNotify();

  const [ data, setData ] = useState(undefined);
  const [ project, setProject ] = useState(session.getStore('selected-commessa'));
  const [ requireUpdate, setRequireUpdate ] = useState(false);
  const [ range, setRange ] = useState(undefined);
  const [ isLoading, setIsLoading ] = useState(false);

  const syncData = useCallback(() => {
    setIsLoading(true);
    req("get-pt-list", { selectedCommessa: project == 'Tutto' ? null : project }).then(res => {
      if (res.success) {
        if(res.data.length > 0) {
          const newData = res.data.map(task => ({
            ...task,
            showChilds: task.record === 'COM' && project == 'Tutto' ? false : task.showChilds
          }));
  
          const minDate = newData.reduce((acc, task) => {
            if(task.dates) {
              task.dates.forEach(date => {
                if(new Date(date.start) < acc) {
                  acc = new Date(date.start);
                }
              });
            } else if(task.start) {
              if(new Date(task.start) < acc) {
                acc = new Date(task.start);
              }
            }
  
            if(task.timesheets) {
              task.timesheets.forEach(timesheet => {
                if(new Date(timesheet.date) < acc) {
                  acc = new Date(timesheet.date);
                }
              });
            }
  
            return acc;
          }, new Date());
  
          const maxDate = newData.reduce((acc, task) => {
            if(task.dates) {
              task.dates.forEach(date => {
                if(new Date(date.end) > acc) {
                  acc = new Date(date.end);
                }
              });
            } else if(task.end) {
              if(new Date(task.end) > acc) {
                acc = new Date(task.end);
              }
            }
  
            if(task.timesheets) {
              task.timesheets.forEach(timesheet => {
                if(new Date(timesheet.date) > acc) {
                  acc = new Date(timesheet.date);
                }
              });
            }
  
            return acc;
          }, new Date());
  
          minDate.setDate(minDate.getDate() - 10);
          maxDate.setDate(maxDate.getDate() + 10);
  
          setData(newData);
          setRange({ from: minDate.toISOString().split('T')[ 0 ], to: maxDate.toISOString().split('T')[ 0 ] });
        } else {
          setData(undefined);
          notify('Errore', "Commessa non esistente.", 'error');
        }
      } else {
        notify(res.error, 'Errore al caricamento dei task.', 'error');
      }

      setIsLoading(false);
    });
  }, [ project ]);

  /* PROJECT CHANGED */
  useEffect(() => {
    if(project !== undefined) {
      syncData();
    }
  }, [ project ]);

  /* UPDATE REQUESTED */
  useEffect(() => {
    if(requireUpdate && !isLoading) {
      syncData();
      setRequireUpdate(false);
    } else if(requireUpdate && isLoading) {
      notify("Attenzione", "C'è già una richiesta in corso.", 'warn');
      setRequireUpdate(false);
    }
  }, [ requireUpdate ]);

  return {
    data,
    setData,
    range,
    isLoading,
    project,
    setProject: (proj) => {
      setProject(proj);
      session.addStore('selected-commessa', proj);
      setRequireUpdate(true);
    },
    resync: () => {
      setRequireUpdate(true)
    }
  };
}

export default useTasks;
import { Select } from "@headlessui/react";


export const FormifyInputStyle = "px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b disabled:text-gray-500 disabled:bg-gray-200";

export const FormifyPaletteStyle = "border-0 bg-transparent w-12 h-8 appearance-none cursor-pointer";

export const FormifyLevelStyle = "flex flex-1 cursor-pointer justify-center items-center w-6 h-6 rounded-md font-semibold bg-white text-gray-800 shadow-[0_0_2px_0_#00000060] data-[checked]:bg-blue-600 data-[checked]:text-white data-[disabled]:bg-gray-200 data-[disabled]:text-gray-800";

export const FormifyComboboxStyle = {
  ComboboxInput: "px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b disabled:text-gray-500 disabled:bg-gray-200 px-2 py-0 border-0 border-b border-gray-300 bg-gray-100 focus:ring-0 focus:ring-b disabled:text-gray-500 disabled:bg-gray-200",
  ComboboxInputArrowButton: "group absolute inset-y-0 right-0 px-3.5",
  ComboboxInputArrowIcon: "text-gray-500 group-data-[hover]:fill-white",
  ComboboxOptions: "z-50 border bg-white empty:invisible shadow-[0_0_4px_0_#00000020]"
}

export const ContextureButtonStyle = "flex justify-start items-center px-3 py-0.5 w-full rounded-sm space-x-2 text-nowrap hover:bg-gray-200";

export const ContexturePanelStyle = "w-full border focus:outline-none border-gray-300 bg-white rounded-sm shadow-lg flex flex-col items-stretch justify-center p-1 min-w-36 z-50";

export const FormifySelectStyle = {
  Select: FormifyInputStyle + " flex items-center justify-start space-x-1 w-full",
  Options: "z-50 border bg-white empty:invisible shadow-[0_0_4px_0_#00000020]"
}
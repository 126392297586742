import Moment from "../../../../modules/Moment/Moment";

const MilestoneCell = ({ record, from, date, className, cellWidth, name, fontSize }) => {
  const offset = Moment(from).duration(date);

  return record == 'M' ? (
    <div className="relative w-3 h-3 cursor-pointer flex items-center">
      <div className={ className ?? "rotate-45 bg-green-500 hover:bg-green-400 scale-75 w-3 h-3" } />

      <div style={ { fontSize } } className="absolute left-full z-20 text-nowrap px-1 pointer-events-none">
        { name }
      </div>
    </div>
  ) : (
    <div className="relative w-3 h-3 cursor-pointer flex items-center">
      <div className={ className ?? "rotate-45 bg-green-500 hover:bg-green-400 scale-75 w-3 h-3" } />

      <div
        className="absolute bg-gray-400 pointer-events-none"
        style={ {
          height: 1,
          width: offset * cellWidth,
          left: -offset * cellWidth,
        } }
      />

      <div style={ { fontSize } } className="absolute left-full z-20 text-nowrap px-1 pointer-events-none">
        { name }
      </div>
    </div>
  );
};

export default MilestoneCell;
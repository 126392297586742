import { useRef, useState } from "react";
import { clsx } from "../../../../modules/Utilkit/Utilkit";
import Flaticon from "../../../../components/Flaticon/Flaticon";


const TableRow = ({ task, setTasks, onContextMenu, cellHeight, fontSize }) => {
  const { budgetHours, actualHours, moved } = task;
  const hoursDifference = budgetHours && actualHours ? (
    Math.floor(Number(budgetHours) - Number(actualHours))
  ) : (
    '-'
  );
  const addClassesBudgRes = actualHours && budgetHours && (Number(budgetHours) - actualHours < 0) ? "bg-red-300 text-red-900" : task.record === 'COM' ? "bg-gray-300" : "bg-white";
  const hoursToFinish = actualHours ? (
    task.code === 'GRP' || moved || task.duration > 8 ? (
      task.hoursToFinish
    ) : (
      '-'
    )
  ) : (
    '-'
  );

  return (
    <tr
      style={ { fontSize: fontSize, height: cellHeight } }
      onContextMenu={ (e) => {
        e.preventDefault();
        onContextMenu({ task, event: e });
      } }
    >
      <TableCell cellHeight={ cellHeight } addClasses={ task.record === 'COM' ? "bg-gray-300" : "bg-white" }>
        <TableFieldWithTaskName task={ task } setTasks={ setTasks } />
      </TableCell>
      <TableCell addClasses={ task.record === 'COM' ? "bg-gray-300" : "bg-white" } cellHeight={ cellHeight }>{ budgetHours ? <div>{ budgetHours }</div> : "-" }</TableCell>
      <TableCell addClasses={ task.record === 'COM' ? "bg-gray-300" : "bg-white" } cellHeight={ cellHeight }>{ actualHours ? Math.floor(actualHours) : '-' }</TableCell>
      <TableCell addClasses={ clsx(addClassesBudgRes) } cellHeight={ cellHeight }>{ hoursDifference }</TableCell>
      <TableCell addClasses={ task.record === 'COM' ? "bg-gray-300" : "bg-white" } cellHeight={ cellHeight }>{ hoursToFinish }</TableCell>
    </tr>
  );
};

const TableCell = ({ children, cellHeight, addClasses, className }) => {
  return (
    <td className="p-0">
      <div
        style={ { height: cellHeight } }
        className={ clsx(
          className ?? "px-2 h-full flex justify-center items-center whitespace-nowrap border-r border-b border-gray-200",
          addClasses
        ) }
      >
        { children }
      </div>
    </td>
  );
};

const TableFieldWithTaskName = ({ task, setTasks }) => {
  const [ showTooltip, setShowTooltip ] = useState(false);

  const ref = useRef(null);

  return (
    <div
      className={ "flex space-x-2 h-full w-36 relative items-center" }
      ref={ ref }
      style={ { paddingLeft: task.showChilds !== undefined ? (task.space ?? 1) * 10 - 10 : (task.space ?? 0) * 10 } }
      onMouseEnter={ () => setShowTooltip(true) }
      onMouseLeave={ () => setShowTooltip(false) }
    >
      { task.showChilds !== undefined && (
        <button
          className={ clsx("w-1", !task.showChilds && "-rotate-90") }
          onClick={ () => setTasks((tasks) => tasks.map((t) => t.id === task.id ? { ...t, showChilds: !t.showChilds } : t)) }
        >
          <Flaticon name="angle-small-down" type="br" />
        </button>
      ) }

      <div className="w-full h-full relative z-10 flex items-center">
        <span
          className={ clsx(
            "text-ellipsis text-nowrap overflow-hidden",
            task.record === 'COM' && "font-bold"
          ) }
        >
          { task.name }
        </span>
      </div>

      { task.name?.length > 1 && (
        <div
          className={ clsx(
            "absolute h-full top-0 z-10 px-1 flex items-center ring-1 ring-red-400",
            showTooltip ? "flex" : "hidden",
            task.record === 'COM' ? "font-bold bg-gray-300" : "bg-white",
          ) }
          style={ {
            left: ref.current?.offsetLeft ?? 0,
            marginLeft: task.showChilds !== undefined ? ((task.space ?? 1) * 10 - 10) : ((task.space ?? 1) * 10 - 12)
          } }
        >
          { task.name }
        </div>
      ) }
    </div>
  );
};

export default TableRow;
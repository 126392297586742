import { useLanguage } from "../../../components/Language";

const MilestonePopup = ({ task: ms }) => {
  const { DICTIONARY: { MILESTONEPOPUP: DICT } } = useLanguage();
  const date = ms.date?.split('-').reverse().join('/');
  const reqDeliveryDate = ms.requiredDeliveryDate?.split('-').reverse().join('/');
  const confDeliveryDate = ms.confirmedDeliveryDate?.split('-').reverse().join('/');

  return (
    <div className="flex flex-col">
      { ms.record == 'M' ? (
        <>
          { /** TASK MILESTONE */ }

          <h1 className="font-semibold border-b border-gray-400 text-center">
            { DICT.MILESTONE }
          </h1>

          <div className="divide-y divide-gray-200 px-2 py-1">
            { /** TASK NAME */ }
            <div className="flex flex-col items-center">
              <h2 className="mb-1 flex justify-center w-full text-center text-gray-400">
                { DICT.TASK_NAME }
              </h2>

              <p className="text-sm">
                { ms.name }
              </p>
            </div>

            { /** DATE */ }
            <div className="flex flex-col items-center">
              <h2 className="mb-1 flex justify-center w-full text-center text-gray-400">
                { DICT.DATE }
              </h2>

              <p className="text-sm">
                { date }
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          {/** ODP */ }
          <h1 className="font-semibold border-b border-gray-400 text-center">
            { DICT.MILESTONE }
          </h1>

          <div className="divide-y divide-gray-200 px-2 py-1">
            { /** MILESTONE SUPPLIER */ }
            { ms.supplier && (
              <div className="flex flex-col items-center">
                <h2 className="mb-1 flex justify-center w-full text-center text-gray-400">
                  { DICT.SUPPLIER }
                </h2>

                <p className="text-sm">
                  { ms.supplier }
                </p>
              </div>
            ) }

            { /** MILESTONE ARTICLE NUMBER */ }
            <div className="flex flex-col items-center text-center">
              <h2 className="mb-1 flex justify-center w-full text-center text-gray-400">
                { DICT.ARTICLE }
              </h2>

              <p className="text-sm">
                ({ ms.articleCode }) { ms.articleDescription }
              </p>
            </div>

            { /** MILESTONE REQUIRED DELIVERY DATE */ }
            <div className="flex flex-col items-center">
              <h2 className="mb-1 flex justify-center w-full text-center text-gray-400">
                { DICT.REQUIRED_DELIVERY_DATE }
              </h2>

              <p className="text-sm">
                { reqDeliveryDate }
              </p>
            </div>

            { /** MILESTONE CONFIRMED DELIVERY DATE */ }
            { ms.confirmedDeliveryDate && (
              <div className="flex flex-col items-center">
                <h2 className="mb-1 flex justify-center w-full text-center text-gray-400">
                  { DICT.CONFIRMED_DELIVERY_DATE }
                </h2>

                <p className="text-sm">
                  { confDeliveryDate }
                </p>
              </div>
            ) }
          </div>
        </>
      ) }
    </div>
  );
};

export default MilestonePopup;
import { Button, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import React, { Fragment } from "react";

const clsx = (...args) => args.filter(Boolean).join(" ");

const switcher = (query, items = {}) => {
  if (items[ query ]) {
    return items[ query ];
  } else if (items[ "default" ]) {
    return items[ "default" ];
  } else {
    return false;
  }
};

const Modality = ({ show, label, children, buttons, onClose, className, image, icon, width, onSubmit, preventClose }) => {
  return (
    <Transition show={ show }>
      <Dialog as="div" className="z-50 focus:outline-none" onClose={ () => !preventClose && onClose() }>
        <TransitionChild
          as={ Fragment }
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed z-50 top-0 left-0 w-full h-full bg-black/50 backdrop-blur-sm" />
        </TransitionChild>

        <div className="fixed z-50 inset-0 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 scale">
            <TransitionChild
              as={ Fragment }
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-110"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-100"
            >
              <DialogPanel style={ { width: width ?? "448px", maxWidth: width ?? "448px" } } className="rounded-lg max-h-[90%] overflow-y-auto shadow-lg bg-white px-3 py-2 border border-gray-300">

                <form onSubmit={ onSubmit ?? ((e) => e.preventDefault()) }>
                  <div className="flex justify-center items-center space-x-4">
                    { icon ? (
                      <i
                        className={clsx(
                          "flex justify-center items-center fi text-[42px]",
                          "fi-" + icon.type + "-" + icon.name,
                          icon.className
                        )}
                      />
                    ) : image && (
                      <img src={ image } className="w-[48px]" />
                    ) }
                    <div className="flex flex-col flex-1">
                      <DialogTitle as="div" className="font-medium">
                        { label }
                      </DialogTitle>
                      <div className={ className }>
                        { children }
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex justify-end space-x-2">
                    { buttons.map(({ name, onClick, type = "button", styleSet = "default", className, disabled = false }, i) => {
                      return (
                        <Button
                          key={ i }
                          type={ type }
                          disabled={ disabled }
                          onClick={ () => onClick && onClick({ onClose }) }
                          className={ clsx(
                            className ?? "inline-flex items-center gap-2 rounded-md px-4 text-sm/6 font-semibold focus:outline-none",
                            className ?? switcher(styleSet, {
                              "error": "border border-red-400 bg-red-600 hover:bg-red-500 text-white disabled:bg-red-300 disabled:border-red-300 disabled:text-red-100 disabled:cursor-not-allowed",
                              "info": "border border-gray-300 bg-blue-600 hover:bg-blue-500 text-white disabled:bg-blue-300 disabled:border-blue-300 disabled:text-blue-100 disabled:cursor-not-allowed",
                              "success": "border border-gray-300 bg-green-600 hover:bg-green-500 text-white disabled:bg-green-300 disabled:border-green-300 disabled:text-green-100 disabled:cursor-not-allowed",
                              "default": "border border-gray-300 bg-gray-50 text-black hover:text-gray-600 disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-100 disabled:cursor-not-allowed"
                            })
                          ) }
                        >
                          { name }
                        </Button>);
                    }) }
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modality;